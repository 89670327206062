import styled from "styled-components"
import colors from "shared/utils/colors"
import { md } from "mill/utils/breakpoints"

export const Checklist = styled.ul`
  list-style: none;
  margin: 4rem 0;
  padding: 0;
`

export const ChecklistItem = styled.li`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 4rem 0 4rem 0;
  border-top: 3px solid ${colors.grayWhisper};

  &:last-of-type {
    border-bottom: none;
  }
`

export const Icon = styled.i`
  font-size: 3rem;
  color: ${props => props.theme.colors[props.color || "primary"]};
  padding: 0 2rem;
  
  @media ${md} {
    font-size: 5rem;
    padding: 0 3rem 0 2rem;
  }
`