import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import styled from "styled-components"
import { Container } from "mill/utils/shared/core"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Button from "shared/components/Button"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"
import NewCampaignReview from "mill/components/NewCampaignReview"
import campaignWizard from "mill/containers/Admin/CampaignWizard"
import { adminCampaignLaunchedPath } from "mill/routes/RouteNames"
import usePageTitle from "mill/hooks/usePageTitle"
import useTrackPage from "mill/hooks/useTrackPage"
import useConfirmationModal from "shared/hooks/useConfirmationModal"
import { sm, md } from "mill/utils/breakpoints"

import LAUNCH_CAMPAIGN from "mill/graphql/LaunchCampaign"

import launchConsole from "./launch_console.png"

const LaunchConsole = styled.div`
  background-image: url(${launchConsole});
  background-position: bottom;
  background-size: cover;
  text-align: center;
  height: 200px;
  padding-top: 54px;
  
  button {
    border-radius: 5px;
    margin-left: -4px;
    width: 240px !important;
    height: 51px !important;
    font-size: 1.5rem;
  }

  @media ${sm} {
    height: 260px;
    padding-top: 70px;

    button {
      margin-left: -5px;
      width: 312px !important;
      height: 66px !important;
      font-size: 2rem;
    }
  }
  
  @media ${md} {
    height: 475px;
    background-position: top center;
    padding-top: 128px;

    button {
      border-radius: 10px;
      margin-left: -11px;
      width: 570px !important;
      height: 122px !important;
      font-size: 3rem;
    }
  }
`

const Review = props => {
  let navigate = useNavigate()
  const [campaignLaunching, setCampaignLaunching] = useState(false)
  usePageTitle("Review Campaign")
  useTrackPage("Campaign Review", { campaignId: props.campaignID })
  const { showConfirmationModal } = useConfirmationModal()
  const {
    featureFlags: { campaignInviteUrlEnabled }
  } = useContext(FeatureFlagsContext)
  const [launchCampaign] = useMutation(LAUNCH_CAMPAIGN, {
    onCompleted: ({ launchCampaign }) => {
      const { campaign, errors } = launchCampaign
      if (errors && errors.length > 0) {
        props.displayNotification({
          text: errors[0]
        })
        setCampaignLaunching(false)
      } else if (campaign) {
        navigate(adminCampaignLaunchedPath(campaign.id))
      }
    }
  })

  const handleClick = () => {
    showConfirmationModal({
      title: `Are you sure you want to invite learners?`,
      text: `Once invited they'll receive an email and the campaign will be active.`,
      yesText: `Got it! I'm ready to invite learners`,
      yesAction: () => {
        launchCampaign({
          variables: {
            campaignId: props.campaignID
          }
        })
        setCampaignLaunching(true)
      },
      noText: `No, I'm not ready to invite learners`
    })
  }

  const buttonDisabled = () => {
    const {
      campaign: {
        name,
        learnerCount,
        activeQuestionsCount,
        startDate,
        state
      }
    } = props
    if (
      state === "active" ||
      campaignLaunching ||
      !name ||
      !activeQuestionsCount ||
      !startDate
    ) {
      if (!learnerCount && !campaignInviteUrlEnabled) {
        return true
      }
      return false
    }
    return false
  }

  const buttonText = () => {
    const { campaign } = props
    if (campaignLaunching) {
      return "Launching..."
    } else if (campaign.state !== "draft") {
      return "Campaign already launched"
    } else if (campaignInviteUrlEnabled && !campaign.learnerCount) {
      return "Launch campaign"
    }
    return "Invite learners"
  }

  return (
    <React.Fragment>
      <Container style={{ maxWidth: "110rem", marginTop: "4rem" }}>
        <Heading level={1}>Invite learners</Heading>
        <Text tone="dark">
          Once you invite learners, they'll receive an email introducing them to{" "}
          {window.PLATFORM_NAME} and the campaign.
        </Text>
      </Container>

      <Container style={{ maxWidth: "110rem" }}>
        {props.campaign && (
          <NewCampaignReview
            campaign={props.campaign}
            inviteUrlEnabled={campaignInviteUrlEnabled}
          />
        )}
      </Container>

      <LaunchConsole>
        <Button
          color="secondary"
          data-qa="launch-campaign"
          disabled={buttonDisabled()}
          size="large"
          onClick={handleClick}
          label={buttonText()}
        />
      </LaunchConsole>
    </React.Fragment>
  )
}

const component = campaignWizard(Review, "invite_learners")
export default component
