import React from "react"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import moment from "moment"
import { object } from "prop-types"
import {
  editAdminCampaignPath,
  adminCampaignLearnersPath,
  adminCampaignQuestionsPath,
  adminCampaignPrimersPath,
  adminCampaignConfidenceSurveyPath,
  adminCampaignSettingsPath
} from "mill/routes/RouteNames"
import { Checklist, ChecklistItem, Icon } from "./styles"

const NewCampaignReview = ({
  campaign: {
    id,
    name,
    mechanism,
    learnerCount,
    activeQuestionsCount,
    activePrimersCount,
    confidenceSurveyStatementsCount,
    startDate
  },
  inviteUrlEnabled
}) => {
  const friendlyStartDate = moment(startDate).format("Do MMMM YYYY")

  const renderIcon = campaignStat => {
    if (campaignStat) {
      return <Icon className="fa fa-check-circle" color="primary" />
    }
    return <Icon className="fa fa-cross-circle" color="error" />
  }

  return (
    <Checklist>
      <ChecklistItem key="1">
        {renderIcon(name)}
        <Box flex="1">
          <Heading level={3} capitalize>
            {mechanism ? mechanism : ""} campaign
          </Heading>
          {name && (
            <Text>
              The title of the campaign is: <em>{name}.</em>
            </Text>
          )}
        </Box>
        <Box paddingLeft="small">
          <Button
            color="primary"
            variant="hollow"
            to={editAdminCampaignPath(id)}
            label="Edit"
          />
        </Box>
      </ChecklistItem>

      <ChecklistItem key="2">
        {inviteUrlEnabled ? renderIcon(true) : renderIcon(learnerCount)}
        <Box flex="1">
          <Heading level={3}>Learners</Heading>
          {learnerCount ? (
            <Text>
              <em>{learnerCount} learners </em>
              will participate in the campaign.
            </Text>
          ) : (
            <Text>No learners have been added.</Text>
          )}
        </Box>
        <Box paddingLeft="small">
          <Button
            color="primary"
            variant="hollow"
            to={adminCampaignLearnersPath(id)}
            label="Edit"
          />
        </Box>
      </ChecklistItem>

      <ChecklistItem key="3">
        {renderIcon(activeQuestionsCount)}
        <Box flex="1">
          <Heading level={3}>Questions</Heading>
          {activeQuestionsCount ? (
            <Text>
              <em>{activeQuestionsCount} questions </em>
              have been added to the campaign.
            </Text>
          ) : (
            <Text>No questions have been added yet.</Text>
          )}
        </Box>
        <Box paddingLeft="small">
          <Button
            color="primary"
            variant="hollow"
            to={adminCampaignQuestionsPath(id)}
            label="Edit"
          />
        </Box>
      </ChecklistItem>

      {mechanism == "embed" && (
        <ChecklistItem key="4">
          {renderIcon(true)}
          <Box flex="1">
            <Heading level={3}>Primer Questions (optional)</Heading>
            {activePrimersCount ? (
              <Text>
                <em>{activePrimersCount} primer questions</em> have been added
                to the campaign.
              </Text>
            ) : (
              <Text>No primers have been added yet.</Text>
            )}
          </Box>
          <Box paddingLeft="small">
            <Button
              color="primary"
              variant="hollow"
              to={adminCampaignPrimersPath(id)}
              label="Edit"
            />
          </Box>
        </ChecklistItem>
      )}

      <ChecklistItem key="5">
        {renderIcon(true)}
        <Box flex="1">
          <Heading level={3}>Confidence survey (optional)</Heading>
          {confidenceSurveyStatementsCount ? (
            <Text>
              <em>{confidenceSurveyStatementsCount} confidence statements</em> have been added to
              the campaign.
            </Text>
          ) : (
            <Text>No confidence statements have been added yet.</Text>
          )}
        </Box>
        <Box paddingLeft="small">
          <Button
            color="primary"
            variant="hollow"
            to={adminCampaignConfidenceSurveyPath(id)}
            label="Edit"
          />
        </Box>
      </ChecklistItem>

      <ChecklistItem key="6">
        {renderIcon(startDate)}
        <Box flex="1">
          <Heading level={3}>Campaign settings</Heading>
          {startDate ? (
            <Text>
              The {mechanism} campaign is scheduled to start on the{" "}
              <em>{friendlyStartDate}</em>.
            </Text>
          ) : (
            <Text>No start date has been scheduled yet.</Text>
          )}
        </Box>
        <Box paddingLeft="small">
          <Button
            color="primary"
            variant="hollow"
            to={adminCampaignSettingsPath(id)}
            label="Edit"
          />
        </Box>
      </ChecklistItem>
    </Checklist>
  )
}

NewCampaignReview.propTypes = {
  campaign: object.isRequired
}

export default NewCampaignReview
